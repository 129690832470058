.banner {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;

	padding: 1rem;
	background-color: #670075;
	color: white;
	font-weight: 600;

	.enroll {
		color: white;
		transition: linear 0.25s;
	}
}

.nav {
	position: sticky;
	top: 0px;
	background-color: white;

	& > div {
		max-width: 70rem;
		margin: 0 auto;
		padding: 0 2rem;

		display: flex;
		justify-content: space-between;
	}
}

.nav.fixed {
	background: rgba(255, 255, 255, 0.6);
}

.logo {
	height: 5rem;
}

.links {
	display: flex;
	align-items: center;
	gap: 2rem;

	a {
		color: #7f75aa;
		font-weight: 600;
		text-decoration: none;
		transition: linear 0.25s;
	}

	a:hover {
		color: #010363;
	}
}

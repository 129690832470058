.footer {
	background-color: #f2f2f2;
	margin-top: auto;

	& > div {
		max-width: 70rem;
		margin: 0 auto;
		padding: 2rem 2rem;

		display: flex;
		justify-content: space-between;
		gap: 5rem;
	}
}

.socials {
	display: flex;
	flex-direction: column;
}

.icons {
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 2rem;

	& > svg {
		font-size: 1.25rem;
	}
}

.disclaimer {
	margin: 3rem 0 0;
	padding: 0;
	max-width: 30rem;
	font-size: 0.7rem;
	color: #666;
}

.links {
	display: flex;
	gap: 5rem;

	& > div {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	a {
		text-decoration: none;
		color: #000;
	}
}

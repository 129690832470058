.app {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.main {
	max-width: 70rem;
	margin: 0 auto;
	padding: 0 2rem;
}

.item {
	display: flex;
	flex-direction: column;
	align-items: center;

	.image {
		width: 15rem;
		height: 15rem;
		cursor: pointer;
		display: flex;
		justify-content: center;

		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}

	.button {
		border: none;
		margin-top: 2rem;
		text-decoration: none;
		color: #fff;
		padding: 0.75rem 2rem;
		background-color: #a00e85;
		cursor: pointer;
	}
}

.homepageImg {
	width: 100%;
	max-height: 30rem;
	object-fit: cover;
	object-position: center top;
}

.sections {
	max-width: 70rem;
	margin: 4rem auto;

	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.features {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 1.75rem;

	.feature {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.25rem;
		padding: 1.75rem 1rem;

		font-size: 0.95rem;
		color: #fff;
		background-color: #9389bc;
		transition: linear 0.25s;
		cursor: pointer;

		svg {
			width: 3rem;
			height: 3rem;
		}

		h3,
		p {
			margin: 0;
			padding: 0;
		}
	}

	.feature:hover {
		background-color: #0045c5;
	}
}

.about {
	p {
		font-size: 1.15rem;
		line-height: 2rem;
	}
}

.programs {
	h2 {
		text-align: center;
	}

	.programsContainer {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 2rem;
	}
}

.program {
	p {
		line-height: 1.75rem;
	}

	a {
		text-decoration: none;
		color: #e30dc2;
		font-size: 0.75rem;
	}

	h3 {
		color: #0045c5;
		font-size: 1.25rem;
	}
}

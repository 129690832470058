.page {
	max-width: 42rem;
	margin: 0 auto;

	p {
		line-height: 1.5rem;
	}
}

.program {
	background-color: #edf8ff;
	padding: 2rem;
	margin-bottom: 2rem;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 2rem;
}

.buttons {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;

	a {
		text-decoration: none;
		color: #fff;
		padding: 0.75rem 1rem;
	}

	.enroll {
		background-color: #34a2ef;
	}

	.contact {
		background-color: #e02664;
	}
}

.title {
	text-align: center;
}

.items {
	display: flex;
	flex-wrap: wrap;
	row-gap: 8rem;
	column-gap: 2rem;
	overflow: scroll;
	padding: 4rem 0;
}

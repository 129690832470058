.page {
	margin: 0 auto;
	max-width: 50rem;

	p {
		line-height: 1.5rem;
	}
}

.content {
	display: flex;
	align-items: flex-start;
	gap: 6rem;
	margin-bottom: 10rem;

	& > div {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	img {
		margin-top: 6rem;
		height: 14rem;
	}

	a {
		margin-top: 2rem;
		text-decoration: none;
		color: #fff;
		padding: 0.75rem 2rem;
		background-color: #a00e85;
	}
}
